/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */

import React, {Component} from 'react';
import { Card, CardText, CardBody, CardTitle, Button, Modal, ModalHeader, ModalBody, ModalFooter   } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import SimpleModal from './SimpleModal.jsx';

class SimpleCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
        modal : false
    };
    this.toggle = this.toggle.bind(this);
  }
  
  toggle() {
    this.setState({
      modal : !this.state.modal
    });
  }
	render() {
		return (
		    <div>
  		    <Card>
    		    <CardBody>
    		      <CardTitle>{this.props.cardTitle}</CardTitle>
    		      <CardText>{this.props.cardText}</CardText>
    		      <Button onClick={this.toggle} color="primary">{this.props.buttonText}</Button>
    		        <div>
    		        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <ModalHeader toggle={this.toggle}>{this.props.modalTitle}</ModalHeader>
                <ModalBody>
                  {this.props.modalBody}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.toggle}>Close</Button>
                </ModalFooter>
                </Modal>
                </div>
         		    
    		      </CardBody>
  	      </Card>
		    </div>
		);
	}
}



SimpleCard.defaultprops = {
    cardTitle : "Default Card Title",
    cardText : "Default Card Text",
    buttonText : "Default",
    linkTo : "/",
    modalTitle : "Default Model Title",
    modalBody : "Default Model Body"
    
      
}

export default SimpleCard;


