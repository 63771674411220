/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2019-10-20  ahj         initial implementation
 */

import React, {Component} from 'react';
//import contract from '/documents/hall/RentalAgreement.pdf';


class HallPicture extends Component {
  
  
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
        <div className="container, App">
          <p>{this.props.Message001}<br/><a target='_blank' href={process.env.PUBLIC_URL + '/images/hall/IMG_1234.jpg'}>Panoramic View of the Hall</a></p>
        </div>
    );
  }
  
  
} 

HallPicture.defaultProps = {
    Message001: "Panoramic view of the main hall"
    
}

export default HallPicture;


