/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2020-05-27  ahj         initial implementation
 */

import React, {Component} from 'react';
import { Jumbotron, Container, Row, Col } from 'reactstrap';


class RedCrossComponent extends Component {

  render() {
    return(
      <div className="container">
        <h2 className="App">{this.props.RedCrossHeader}</h2>
        <h2 className="App">{this.props.RedCrossDate}</h2>
        <h2 className="App">{this.props.RedCrossTime}</h2>
        <h3 className="App">{this.props.RedCrossDisclaimer}</h3>
        <div>
          <Jumbotron fluid>
            <Container fluid>
              <div id="myHome">
              <img className="img-fluid" src="./images/redcross/blood_mobile.jpeg" alt="Red Cross Blood Drive"/>
              </div>
            </Container>
          </Jumbotron>
        </div>
      </div>
    );
  }
}

RedCrossComponent.defaultProps = {
    RedCrossHeader: "Red Cross Blood Drive",
    RedCrossDate: "June 26, 2020",
    RedCrossTime: "3:00 PM - 7:00 PM",
    RedCrossDisclaimer: "Not Affliated With Wright City Lions Club"
      
}
export default RedCrossComponent;