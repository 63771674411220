/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */

import React, { Component } from 'react';
import "../../css/Footer.css";

class Footer extends Component {
  getYear() {
      return new Date().getFullYear();
  } 
  render() {
      return(
          <div id="ahj-footer" className="container-fluid">
              <div className="row">
                  <div className="col-sm-4">
                      <p>&copy;  {this.getYear()} {this.props.FooterInfo}</p>
                  </div>
                  <div className="col-sm-4">
                      <p>{this.props.MailingAddress}</p>
                  </div>
                  <div className="col-sm-4">
                      <p>{this.props.PhoneNumber}</p>
                  </div>
              </div>
          </div>
      );
  } 
}

Footer.defaultProps = {
    FooterInfo: "Wright City Lions Club",
    MailingAddress : "PO Box 85 Wright City, MO 63390",
    PhoneNumber: "Phone Number: 636-745-3320"
      
}

export default Footer;