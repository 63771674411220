/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 *    2020-06-24  ahj         removing the life line nav link since the event has passed
 */
//  <Nav className="ml-auto" pills="true" navbar>
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

class Header extends Component {
  constructor(props) {
    super(props);
    
    this.toggle = this.toggle.bind(this);
    
    this.state = {
        isOpen: false
    };
    
    
  }
  
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  
  render() {
    return(
        <div>
          <Navbar color="light" light expand="md">
            <NavbarBrand href="/"><img id="brand-image" src="images/Lions60x60.png"/></NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav pills>
                <NavItem>
                  <NavLink tag={Link} to="/">Home</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/aboutus">About Us</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/hallrental">Hall Rental</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/contactus">Contact Us</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </div>
    );
  }
  
}


export default Header;

