/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 *    2020-01-29  ahj         removed life line screen and added washer tournament
 *    2020-05-20  ahj         added Life Line Screening 
 *    2020-06-24  ahj         removing the life line event since it is complete
 */



import React, { Component } from 'react';
import { BrowserRouter as Router, HashRouter, Switch, Route, Link } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Home  from './pages/Home.jsx';
import AboutUs  from './pages/AboutUs.jsx';
import HallRental  from './pages/HallRental.jsx';
import ContactUs  from './pages/ContactUs.jsx';
import Washers from './pages/Washers.jsx';
import MoscowMills from './pages/MoscowMills.jsx';
import LifeLine from './pages/LifeLine.jsx';
import RedCross from './pages/RedCross.jsx';
import NotFound  from './pages/NotFound.jsx';
import Header from './components/common/Header.jsx';
import Footer from './components/common/Footer.jsx';

class App extends Component {
  render() {
    return (
        <div>
          <div>
          <Router>
            <div>
              <Header />
              <Switch>
                <Route exact path='/' component={Home} />
                <Route path='/aboutus' component={AboutUs} />
                <Route path='/hallrental' component={HallRental} />
                <Route path='/contactus' component={ContactUs} />
                <Route component={NotFound} />
              </Switch>
              <Footer />
            </div>
          </Router>
          </div>
        </div>
    );
  }
}

export default App;
