/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 *    2018-11-15  ahj         ** to do figure out how to add a date picker
 *    2018-12-02  ahj         Added e-mail capabilities
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Recaptcha from 'react-recaptcha';
import { Button, Form, FormGroup, FormFeedback, Label, Input, FormText, Row, Col} from 'reactstrap';

class ContactUsForm extends Component {
  
  constructor(props) {
    super(props)
//    this.handleChange = this.handleChange.bind(this);
//    this.onSubmit = this.OnSubmit.bind(this);
//    this.recaptchaLoaded = this.recpatchaLoaded.bind(this);
//    this.recaptchaVerifyCallback = this.recpatchaVerifyCallback.bind(this);
    this.state = {
      emailAddress : "",
      firstName : "",
      lastName : "",
      selectedDate : new Date(),
      sendCopy : false,
      subject : "",
      phone : "",
      message : "",
      isVerified : false
    };
  }
  onFormSubmit = (e) =>  {
    e.preventDefault();
    fetch('phpdemo.php')
        .then((res) => { return res.json() })
        .then((data) => {
            console.log(data);
        })
    alert("Contact Page Is Still Under Construction\nPlease manually send us an e-mail");
  }
  
  onEnterKey = (e) =>  {
    if (e.keyCode === 13) {
      e.preventDefault();
      return false;
    }
  }
  
  onFormSubmitPost = (e) => {
    e.preventDefault();
    const url = 'http://www.site602.local/api/contactus.php';
    const data = {
        emailAddress : this.state.emailAddress,
        firstName : this.state.firstName,
        lastName : this.state.lastName,
        selectedDate : this.state.selectedDate,
        sendCopy : this.state.sendCopy,
        emailBody : this.state.message
    }
    
    var emailMessage = "Name: " + this.state.firstName + " " + this.state.lastName + "\n";
    emailMessage += "Phone Number: " + this.state.phone + "\n";
    emailMessage += "Date Selected: " + this.state.selectedDate + "\n";
    emailMessage += "Email Address: " + this.state.emailAddress + "\n\n";
    emailMessage += this.state.message;
    
    const testData = {
        emailTo : "wrightcitylions@yahoo.com",
        emailFrom : this.state.emailAddress,
        emailSubject : this.state.subject,
        emailMessage : emailMessage
       
    }
 
    
    fetch('https://api.wrightcitylions.com/api/contactus.php', {
      method : 'POST',
      body : JSON.stringify(testData),
    })
    .then((res) => { 
      console.log(res.status);
      return res.json() })
    .then((data) => {
      console.log(data);
      console.log("Email Successfully Sent");
    })
    .catch(error => console.log('Error', error))
    
    // resetting form variables
    
    this.setState( {
      emailAddress : "",
      firstName : "",
      lastName : "",
      selectedDate : "",
      sendCopy : false,
      subject : "",
      phone : "",
      message : ""
    });     

    
  }
  
  onFormReset = (e) =>  {
      e.preventDefault()
      if (window.confirm("Are you sure?")) {
        this.setState( {
          emailAddress : "",
          firstName : "",
          lastName : "",
          selectedDate : "",
          sendCopy : false,
          subject : "",
          phone : "",
          message : ""
        });       
      }
    }

  
  handleChange(e) {
    
    switch (e.target.id) {
      case "emailAddress":
        this.setState( {
          emailAddress : e.target.value
        });
        break;
      case "firstName":
        this.setState( {
          firstName : e.target.value
        });
        break;
      case "lastName":
        this.setState( {
          lastName : e.target.value
        });
        break;
      case "subject":
        this.setState( {
          subject : e.target.value
        });
        break;
      case "phone":
        this.setState( {
          phone : e.target.value
        });
        break;
      case "message":
        this.setState( {
          message : e.target.value
        });
        break;
      case "selectedDate":
        //alert(e.target.value)
        this.setState( {
          selectedDate : e.target.value
        });   
        break;
      default:
        break;
    }
  }
  recpatchaLoaded = () => {
//    console.log("Recaptcha successfully loaded");
  }

  recaptchaVerifyCallback= (response) => {
    if (response) {
//      console.log("recaptchaVerifyCallback: " + response);
      this.setState( {
        isVerified : true
      });    
    }
  }
  
  recaptchaExpiredCallback = () => {
//    console.log("Verification Expired");
    this.setState( {
      isVerified : false
    });    
  }
  render() {
    console.log("renderer");
    return(
      <div className="container">
        <Form onSubmit={this.onFormSubmitPost} onReset={this.onFormReset} onKeyDown={this.onEnterKey}>
        <Row>
          
          <FormGroup>
            <Label for="firstName">First Name</Label>
            <Col sm={10}>
              <Input type="text" value={this.state.firstName} id="firstName" onChange={this.handleChange.bind(this)} placeholder="John" required/>
            </Col>
          </FormGroup>
          <FormGroup>
            <Label for="lastName">Last Name</Label>
            <Col sm={10}>
              <Input type="text" value={this.state.lastName} id="lastName" onChange={this.handleChange.bind(this)} placeholder="Doe" required/>
            </Col>
          </FormGroup>
          <FormGroup>
              <Label for="phone">Phone Number</Label>
              <Col sm={10}>
                <Input type="text" value={this.state.phone} id="phone" onChange={this.handleChange.bind(this)} placeholder="(555) 555-5555" required/>
              </Col>
          </FormGroup>

        </Row>
        <FormGroup>
            <Label for="emailAddress">Email</Label>
            <Col sm={10}>
              <Input type="email" value={this.state.emailAddress} id="emailAddress" onChange={this.handleChange.bind(this)} placeholder="JohnDoe@example.com" required />
            </Col>
          </FormGroup>            
          <FormGroup>
            <Label for="selectedDate">Desired Date</Label>
            <Col sm={10}>
              <Input type="date" value={this.state.selectedDate} name="selectedDate" onChange={this.handleChange.bind(this)} id="selectedDate" placeholder="date placeholder"/>
            </Col>
            </FormGroup>
            <FormGroup>
              <Label for="subject">Subject</Label>
              <Col sm={10}>
                <Input type="text" value={this.state.subject} id="subject" onChange={this.handleChange.bind(this)} placeholder="Email Subject" required/>
              </Col>
            </FormGroup>
            <FormGroup>
            <Label for="message">Message</Label>
            <Col sm={10}>
              <Input type="textarea" value={this.state.message} id="message" onChange={this.handleChange.bind(this)} placeholder="Please type in your message" required/>
            </Col>
          </FormGroup>
          <Recaptcha
            sitekey="6LcEZH4UAAAAANNztk3FZIVwJHcug-Zwd0_xWfWB"
            render="explicit"
            verifyCallback={this.recaptchaVerifyCallback}
            expiredCallback={this.recaptchaExpiredCallback}
            onloadCallback={this.recpatchaLoaded}
          />
          <Button style={{ margin: 30}} color="primary" type="submit" disabled={! this.state.isVerified}>Submit</Button>
          <Button style={{ margin: 30}} color="danger" type="reset">Reset</Button>
        </Form>
        <br/>
              
      </div>
    );
  }
}

export default ContactUsForm;
