/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2020-05-27  ahj         initial implementation
 */

import React, {Component} from 'react';
import { Jumbotron, Container, Row, Col } from 'reactstrap';


class LifeLineComponent extends Component {

  render() {
    return(
      <div className="container">
        <h2 className="App">{this.props.LifeLineHeader}</h2>
        <h2 className="App">{this.props.LifeLineDate}</h2>
        <h3 className="App">{this.props.LifeLineDisclaimer}</h3>
        <div>
          <Jumbotron fluid>
            <Container fluid>
              <div id="myHome">
              <img className="img-fluid" src="./images/lifeline/20200617.png" alt="Life Line Screening"/>
              </div>
            </Container>
          </Jumbotron>
        </div>
      </div>
    );
  }
}

LifeLineComponent.defaultProps = {
    LifeLineHeader: "Life Line Screening",
    LifeLineDate: "June 17, 2020",
    LifeLineDisclaimer: "Not Affliated With Wright City Lions Club"
      
}
export default LifeLineComponent;