/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */

import React, { Component } from 'react';
import AboutUsComponent from '../components/PageComponents/AboutUsComponent.jsx';


class AboutUs extends Component {
  render() {
    return(
        <div>
          <div>
            <AboutUsComponent />
          </div>
        </div>
    );
  }
}


export default AboutUs;