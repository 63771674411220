/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 *    
 *    
 *    https://coolestguidesontheplanet.com/install-apache-mysql-php-and-phpmyadmin-on-macos-high-sierra-10-13/
 */

import React, { Component } from 'react';
import HomeComponent from '../components/PageComponents/HomeComponent.jsx';


class Home extends Component {
  render() {
    return(
        <div>
            <div>
              <HomeComponent />
            </div>
        </div>
    );
  }
}


export default Home;