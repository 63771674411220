/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class HPJonesTable extends Component {
    constructor(props) {
        super(props);
        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._renderTableRow = this._renderTableRow.bind(this);
        this._renderTable = this._renderTable.bind(this);
        this._renderTableColumn = this._renderTableColumn.bind(this);
        this._renderTableRow = this._renderTableRow.bind(this);
    }
    
    
    _renderTableHeader(columnSpanCount, textAlign) {
        return (
            <tr>
                <th colspan={columnSpanCount}>{this.props.tableHeader}</th>
            </tr>
        );
    }

    _renderTableColumn(value) {
        return ( <td>{value}</td>)
        
    }
    
    _renderTableRow(item) {
        console.log("_renderTableRow " + item.length);
        console.log(item);
        return (
            ((item) => {
               var tempArray = [1];
               tempArray[0] = <tr> {item.map(this._renderTableColumn)}</tr>
               return tempArray
            })(item)
        );
        
    }

    
    
    _renderTable(tableData, columnCount) {
        return (
                ((tableData,columnCount) => {
                    // determine number of rows needed - assuming 4 columns per row (hard coded for now)
                    var k = tableData.length;
                    var rows = Math.ceil(k/columnCount);
                    var temp = new Array(rows);
                    var output = new Array(rows);
                    for (var i = 0; i < temp.length; ++i) {
                        temp[i] = new Array(columnCount);
                    }
                    
                    var current_row = -1;
                    var column_count = 0;
                    var j = 0;
                    for (var i in tableData) {
                        if ((j % columnCount) === 0) {
                            ++current_row;
                            column_count = 0;
                        }
                        temp[current_row][column_count] = tableData[i].name;
                        ++j;
                        ++column_count
                    }
                    while (((j % columnCount) !== 0)) {
                        temp[current_row][column_count] = '';
                        ++j;
                        ++column_count;
                    }
                    
                    output = temp.map(this._renderTableRow);
                    
                    return output;
                })(tableData, columnCount)
            )
             
    }
    
    render() {
        return (
            <div>
                <table className={this.props.tableClassName}>
                    <thead>
                        {this._renderTableHeader(this.props.columnSpan)}
                    </thead>
                    <tbody>
                        {this._renderTable(this.props.tableData, this.props.columnCount)}
                    </tbody>
                </table>
            </div>
        );
    }
}

HPJonesTable.propTypes = {
    columnCount: PropTypes.number,
    tableData:   PropTypes.array,
    columnSpan:  PropTypes.number,
}

HPJonesTable.defaultProps = {
    columnCount : 0,
    columnSpan  : 0,
    tableHeader : 'Members',
    tableClassName : 'table table-striped table-responsive-md',
    tableData   : [
                   {id: 1, name: 'Austin'},
                   {id: 2, name: 'Leah'},
                   {id: 3, name: 'Angela'},
                   {id: 4, name: 'Tanya'},
                   {id: 5, name: 'Donna'},
                   {id: 6, name: 'Chris'},
                   {id: 7, name: 'Steve'},
                   {id: 8, name: 'Erin'},
                   {id: 9, name: 'Gretchen'},
                   {id: 10, name: 'Donald'},
                   {id: 11, name: 'Freddy'},
                 ]
}

export default HPJonesTable