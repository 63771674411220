/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */
import React, {Component} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

class Officers extends Component {
   constructor(props) {
     super(props);
     
     this.state = {
 
         originalOfficers : [
             {
                 id: 1,
                 office: "President",
                 name: "Melvin Hunziker"
             },
             {
                 id: 2,
                 office: "First Vice President",
                 name: "Rev. Harry Ring"
             },
             {
                 id: 3,
                 office: "Second Vice President",
                 name: "Rev. William Winter"
             },
             {
                 id: 4,
                 office: "Third Vice President",
                 name: "Walter Marty"
             },
             {
                 id: 5,
                 office: "Secretary",
                 name: "James Mollenbeck"
             },
             {
                 id: 6,
                 office: "Treasurer",
                 name: "Jimmy Williams"
             },
             {
                 id: 7,
                 office: "Lion Tamer",
                 name: "August J. Marten"
             },
             {
                 id: 8,
                 office: "Tail Twister",
                 name: "Carl Garrett"
             }          
           ],
       originalOfficerColumns :  [
          {
            dataField: "office",
            text: "Office"
          },
          {
            dataField: "name",
            text: "Officer's Name"
          }
        ]
     };
   }

   render() {
     return (
         <div>
           <BootstrapTable striped hover keyField='id' data={ this.state.originalOfficers } columns={ this.state.originalOfficerColumns } />
         </div>
     )
   }
}
export default Officers;