/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2019-12-26  ahj         initial implementation - note temporary page until 4/18/2020
 */

import React, { Component } from 'react';
import MoscowMillsComponent from '../components/PageComponents/MoscowMillsComponent.jsx';


class MoscowMills extends Component {
  render() {
    return(
        <div>
            <div>
              <MoscowMillsComponent />
            </div>
        </div>
    );
  }
}


export default MoscowMills;