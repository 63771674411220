/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2020-01-29  ahj         initial implementation
 */

import React, {Component} from 'react';
import { Jumbotron, Container, Row, Col } from 'reactstrap';


class MoscowMillsComponent extends Component {

  render() {
    return(
      <div className="container">
        <h2 className="App">{this.props.MoscowMillsHeader}</h2>
        <h2 className="App">{this.props.MoscowMillsDate}</h2>
        <h3 className="App">{this.props.MoscowMillsDisclaimer}</h3>
        <div>
          <Jumbotron fluid>
            <Container fluid>
              <div id="myHome">
              <img className="img-fluid" src="./images/moscowmills/MoscowMills.jpg" alt="Moscow Mills Tournament"/>
              </div>
            </Container>
          </Jumbotron>
        </div>
      </div>
    );
  }
}

MoscowMillsComponent.defaultProps = {
    MoscowMillsHeader: "Moscow Mills Poker Tournament",
    MoscowMillsDate: "March 20, 2020",
    MoscowMillsDisclaimer: "Not Affliated With Wright City Lions Club"
      
}
export default MoscowMillsComponent;