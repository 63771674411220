/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */

import React, {Component} from 'react';
import HistoryComponent from './HistoryComponent.jsx';
import Officers from './Officers.jsx';
import CharterMembers from './CharterMembers.jsx';
import LionsClubPurpose from './LionsClubPurpose.jsx';

class AboutUsComponent extends Component {

  render() {
    return(
        <div className="container">
          <h2 className="App">{this.props.AboutUsHeader}</h2>
          <HistoryComponent />
          <h2 className="App">{this.props.AboutUsMember}</h2>
          <Officers/>
          <CharterMembers/>
          <h2 className="App">{this.props.AboutUsPurpose}</h2>
          <LionsClubPurpose/>
        </div>
    );
  }
}

AboutUsComponent.defaultProps = {
    AboutUsHeader: "History of the Wright City Lions Club",
    AboutUsMember: "Original Officers and Charter Members",
    AboutUsPurpose : "Lions Club Purpose"
      
}
export default AboutUsComponent;