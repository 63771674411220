/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */

import React, {Component} from 'react';
import HallCarousel from './HallCarousel.jsx';
import HallFigures from './HallFigures.jsx';
import HallContract from './HallContract.jsx';
import HallPicture from './HallPicture.jsx';
class HallRentalComponent extends Component {

  render() {
    return(
        <div>
          <h1 className="App">{this.props.HallRentalInfo}</h1>
          <div>
            <HallFigures/>
            <HallCarousel/>
            <HallContract/>
            <HallPicture/>
          <p/>
          </div>
        </div>
    );
  }
}

HallRentalComponent.defaultProps = {
    HallRentalInfo: "Wright City Lions Club - Hall Pictures",
    CustomMessage : "Pictures of the Wright City Lions Club"
}
export default HallRentalComponent;