/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */
import React, {Component} from 'react';

class HallFigures extends Component {
  render() {
    return(
        <div className="container">
          <p className="App">{this.props.HallFact001}</p>
          <p className="App">{this.props.HallFact002}</p>
          <p className="App">{this.props.HallFact003}</p>
          <p className="App">{this.props.HallFact004}</p>
        </div>
    );
  }  
}

HallFigures.defaultProps = {
    HallFact001: "The Wright City Lions Club main building is 80 feet by 60 feet and " +
      "has a wooden floor.  The backroom, separated by the accordian doors provides an additional " +
      "30 feet by 60 feet of space.  " +
    "When you rent the Wright City Lions Club, you get tables and chairs; use of a " +
    "commercial grade ice machine and a full kitchen with a walk in cooler.  The choice of tables " +
    "include 32 5 foot diameter round tables or 50+ 8 foot long rectangular tables.  You can use both.  " +
    "The Wright City Lions Club will accomodate approximately 350-400 people.",
    HallFact002 : "As the renter, you provide food, beverages, alcohol, plates, silverware, glasses " +
    "and a bartender for serving alcohol.",
    HallFact003 : "Please call or drop us an e-mail to schedule a visit today!",
    HallFact004 : "As of 8/28/2019, the State of Missouri requires the Wright City Lions Club " +
    "to collect sales tax on all hall rentals.  If you have a sales tax exemption, please present it " +
    "at the time of booking the hall."
      
}
export default HallFigures;
