/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */
import React, { Component } from 'react';
import NotFoundComponent from '../components/PageComponents/NotFoundComponent.jsx';


class NotFound extends Component {
  render() {
    return(
        <div>
            <div>
              <NotFoundComponent />
            </div>
        </div>
    );
  }
}


export default NotFound;