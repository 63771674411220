/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2020-01-29  ahj         initial implementation
 */

import React, {Component} from 'react';
import { Jumbotron, Container, Row, Col } from 'reactstrap';


class WashersComponent extends Component {

  render() {
    return(
      <div className="container">
        <h2 className="App">{this.props.WashersHeader}</h2>
        <h2 className="App">{this.props.WashersDate}</h2>
        <div>
          <Jumbotron fluid>
            <Container fluid>
              <div id="myHome">
              <img className="img-fluid" src="./images/washers/2020-Washers.png" alt="Washer Tournament"/>
              </div>
            </Container>
          </Jumbotron>
        </div>
      </div>
    );
  }
}

WashersComponent.defaultProps = {
    WashersHeader: "Wright City Lions Washer Tournament",
    WashersDate: "April 18, 2020",
      
}
export default WashersComponent;