/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types';



class LionsClubPurpose extends Component {

    constructor(props) {
        super(props);
    }
    
    render() {
        return(
            <div>
                <p>{this.props.purposeStatement}</p>
            </div>
        );
    }
}

LionsClubPurpose.propTypes = {
        purposeHeader: PropTypes.string,
        purposeStatement:   PropTypes.string,
    }

LionsClubPurpose.defaultProps = {
    purposeHeader : 'Lions Club Purpose',
    purposeStatement : 'The Motto of the Wright City Lions Club is "We Serve".  The Wright City Lions Club primarily serves by supporting local sight programs at local schools.  The Wright City Lions Club regularly utilizes profits from fund raising activities to maintain the building and donate to the Missouri Eye Institute in Columbia, MO.'

}


export default LionsClubPurpose