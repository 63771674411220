/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-10  ahj         initial implementation
 */

import React, { Component } from 'react'
class HistoryComponent extends Component {
    render() {
        return(
          <div>
              <p>{this.props.AboutUsInfo1}</p>
              <p>{this.props.AboutUsInfo2}</p>
              <p>{this.props.AboutUsInfo3}</p>
          </div>
      );
    }
    
}
HistoryComponent.defaultProps = {
        AboutUsInfo1 : "The Wright City Lions Club was organized on July 11, 1960.  " +
        		"The Jonesburg Lions Club sponsored the Wright City Lions Club.  " +
        		"The Wright City Lions Club received its charter on September 24, 1960.  " +
        		"The charter was presented on October 4, 1960.",
    		AboutUsInfo2 : "The Wright City Lions Club International Dues were first due on September 1, 1960.  " +
    		    "Club dues were set at $12.00 per quarter with meals included.  " +
  		      "The meeting dates were originally set as the second and fourth Monday of every month.  " +
  		      "The original meeting place was Davis Cafe (which is now Ruiz Mexican Restaurant).  " +
  		      "Meetings are now the second Monday of every month at the Wright City Lions Club.",
	      AboutUsInfo3 : "The exisiting Wright City Lions Club building was built in 1982, after a tornado destroyed the " +
	          "previous building.  The Wright City Lions Club building serves the community in many ways, " +
	          "ranging from a place to cast ballots for local, state, and federal elections to hosting wedding " +
	          "receptions at a reasonable price."
    }
export default HistoryComponent;
