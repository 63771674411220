/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */

import React, {Component} from 'react';
import HPJonesCarousel from "../common/HPJonesCarousel.jsx";

class HallCarousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      slideItems : [
       {
         src: 'images/hall/IMG_1048.jpg',
         altText: 'The stage is approximately 18 feet by 10 feet',
         caption: 'The stage is approximately 18 feet by 10 feet'
       },
       {
         src: 'images/hall/IMG_0912.jpg',
         altText: 'The main hall is 80 feet by 60 feet',
         caption: 'The main hall is 80 feet by 60 feet'
       },
       {
         src: 'images/hall/IMG_1236.jpg', 
         altText: 'The backroom is 60 feet by 30 feet',
         caption: 'The backroom is 60 feet by 30 feet'
       },
       
       {
         src: 'images/hall/pg4.jpg',
         altText: 'Head table is between the air conditioning units',
         caption: 'Head table is between the air conditioning units'
       },
       {
         src: 'images/hall/pg5.jpg',
         altText: 'The couple chose to rent chairs in this picture',
         caption: 'The couple chose to rent chairs in this picture'
       },
       {
         src: 'images/hall/pg7.jpg',
         altText: 'This slide shows the head table up on stage',
         caption: 'This slide shows the head table up on stage'
       },
       {
         src: 'images/hall/pg8.jpg',
         altText: '8 people fit around a round table comfortably',
         caption: '8 people fit around a round table comfortably'
       },
       {
         src: 'images/hall/pg9.jpg', 
         altText: 'Picture Includes Backroom',
         caption: 'Picture Includes Backroom'
       },
       {
         src: 'images/hall/IMG_1238.jpg',
         altText: 'Picture of the kitchen',
         caption: 'Picture of the kitchen'
       },
       {
         src: 'images/hall/IMG_1239.jpg', 
         altText: 'Walk-in cooler available to renters',
         caption: 'Walk-in cooler available to renters'
       },
       {
         src: 'images/hall/pg11.jpg',
         altText: 'Slide 11',
         caption: 'Courtesy of Butler Pantry'
       }
     ]
  };
    
  }
  render() {
    return(
        <div className="container, App" id="myTest">
          <HPJonesCarousel 
            slideItems = {this.state.slideItems}
          />
        </div>
    );
    
  }
}

export default HallCarousel;



