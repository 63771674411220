/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */

import React, {Component} from 'react';
//import contract from '/documents/hall/RentalAgreement.pdf';


class HallContract extends Component {
  
  
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
        <div className="container, App">
          <p>{this.props.Message001}<br/><a target='_blank' href={process.env.PUBLIC_URL + '/documents/hall/RentalAgreement.pdf'}>Rental Agreement</a></p>
        </div>
    );
  }
  
  
} 

HallContract.defaultProps = {
    Message001: "The Wright City Lions Club requires renters to read and sign the agreement " +
    "provided in the link below."  ,
    Message002 : "Pictures of the Wright City Lions Club"
}

export default HallContract;


