/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */

import React, {Component} from 'react';

class NotFoundComponent extends Component {

  render() {
    return(
        <div>
          <h1>{this.props.NotFoundInfo}</h1>
        </div>
    );
  }
}

NotFoundComponent.defaultProps = {
    NotfoundInfo: "Page Not FOund"
}
export default NotFoundComponent;