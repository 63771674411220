/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */

import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class SimpleModal extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        modal : false
    };
    this.toggle = this.toggle.bind(this);
  }
  
  toggle() {
    this.setState({
      modal : !this.state.modal
    });
  }
  
  render() {
    return (
        <div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
            <ModalHeader toggle={this.toggle}>{this.props.ModalTitle}</ModalHeader>
            <ModalBody>
              {this.props.ModalBody}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={this.toggle}>Close</Button>
            </ModalFooter>
          </Modal>
        </div>
    );
  }
}

SimpleModal.defaultprops = {
    ModalTitle : "Default Modal Title",
    ModalBody : "Default Modal Body"
}  
  


export default SimpleModal;