/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */

import React, {Component} from 'react';
import { Card, CardText, CardBody, CardTitle, Button  } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class SimpleCard extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
		    <div>
  		    <Card>
    		    <CardBody>
    		      <CardTitle>{this.props.cardTitle}</CardTitle>
    		      <CardText>{this.props.cardText}</CardText>
    		      <Button tag={Link} to={this.props.linkTo} color="primary">{this.props.buttonText}</Button>
    		    </CardBody>
  	      </Card>
		    </div>
		);
	}
}



SimpleCard.defaultprops = {
    cardTitle : "Default Card Title",
    cardText : "Default Card Text",
    buttonText : "Default",
    linkTo : "/"
      
}

export default SimpleCard;


