/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */

import React, {Component} from 'react';
import { Jumbotron, Container, Row, Col } from 'reactstrap';
import SimpleCard from "../common/SimpleCard.jsx";
import SimpleCardModal from "../common/SimpleCardModal.jsx";
import "../../css/Home.css";

class HomeComponent extends Component {

  render() {
    
    return(
        <div>
        <h1 className="App" >{this.props.HomeInfo}</h1>
          <div>
            <Jumbotron fluid>
              <Container fluid>
                <div id="myHome">
                  <img className="img-fluid" src="./images/hall.jpg" alt="Wright City Lions Club"/>
                </div>
                <p className="lead" id="myh3">{this.props.MessageLine1}<br/>{this.props.MessageLine2}<br/>
                {this.props.MessageLine3}<a href={this.props.EmailAddress}>{this.props.DisplayEmailAddress}</a>
                </p>
                
              </Container>
            </Jumbotron>
          </div>
          <div>
            <Row>
              <Col sm="4">
                <SimpleCardModal
                  cardTitle = "Directions"
                  cardText = "Click on Get Directions to get driving directions"
                  buttonText = "Get Directions"
                  modalTitle = "Directions"
                  modalBody = {<div>{this.props.DrivingDirectionsStLouis}<br/><br/>{this.props.DrivingDirectionsColumbia}</div>}
                />
              </Col>
              <Col sm="4">
                <SimpleCard
                  cardTitle = "About Us"
                  cardText = "Learn about the history of the Wright City Lions Club"
                  buttonText = "About Us"
                  linkTo = "/aboutus"
                />
              </Col>
              <Col sm="4">
                <SimpleCard
                  cardTitle = "Contact Us"
                  cardText = "Need more information, call or send us an e-mail"
                  buttonText = "Contact Us"
                  linkTo = "/contactus"
                />
              </Col>
            </Row>
          </div>
        </div>
    );
  }
}

HomeComponent.defaultProps = {
    HomeInfo : "Welcome to the Wright City Lions Club",
    MessageLine1 : "The Wright City Lions Club is located at 678 Westwoods Road, Wright City, MO 63390 ",
    MessageLine2 : "The Mailing Address is PO Box 85, Wright City, MO 63390",
    MessageLine3 : "Please contact us at (636) 745-3320 or send us an e-mail to ",
    MessageLine4 : "Due to COVID-19 restrictions, maximum hall occupancy is 110 people",
    EmailAddress : "mailto:wrightcitylions@yahoo.com",
    DisplayEmailAddress : "wrightcitylions@yahoo.com",
    DrivingDirectionsStLouis : "From St. Louis, MO. merge onto I-70 West. " +
    		"Take EXIT 200 toward MO-F/Wright City/MO-J. Stay straight to go onto North Service Road. " +
    		"Take the first right onto Westwoods Road. Drive approximately one half mile. " +
    		"Wright City Lions Club 678 Westwoods Road is on the left.",
		DrivingDirectionsColumbia : "From Columbia, MO merge onto I-70 East. " +
    		"Merge onto I-70 E/US-40 E." +
    		"Take EXIT 199 toward MO-J/Wright City/MO-H/MO-F. " +
    		"Turn left onto W Service Road S. " +
    		"Go straight through the four way stop onto Wildcat Drive. " +
    		"Take Wildcat Drive until it ends. " +
    		"Turn right onto Westwoods Road. " +
    		"Drive approximately three tenths of a mile. " +
    		"Wright City Lions Club 678 Westwoods Road is on the right.",
  		
      
      
}
export default HomeComponent;




