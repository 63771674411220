/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */

import React, { Component } from 'react';
import ContactUsComponent from '../components/PageComponents/ContactUsComponent.jsx';


class ContactUs extends Component {
  render() {
    return(
        <div>
            <div>
              <ContactUsComponent />
            </div>
        </div>
    );
  }
}


export default ContactUs;