/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */
import React, { Component } from 'react'
import HPJonesTable from '../common/HPJonesTable.jsx';


class CharterMembers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            names: [
               { "id" : "1", "name" : "Charles E. Brandes"},
               { "id" : "2", "name" : "Melvin Hunziker" },
               { "id" : "3", "name" : "Walter A. Pauk" },
               { "id" : "4", "name" : "Robert I. Tomek" },
               { "id" : "5", "name" : "Lloyd Bruning" },
               { "id" : "6", "name" : "Bernard Leykamp" },
               { "id" : "7", "name" : "Harry Ring" },
               { "id" : "8", "name" : "Jimmy Williams" },
               { "id" : "9", "name" : "Carl Garrett" },
               { "id" : "10", "name" : "August J. Marten" },
               { "id" : "11", "name" : "Larry Rose" },
               { "id" : "12", "name" : "William Winter" },
               { "id" : "13", "name" : "Lloyd Gresham" },
               { "id" : "14", "name" : "Walter Marty" },
               { "id" : "15", "name" : "Mike Schreiner" },
               { "id" : "16", "name" : "William T. Wood" },
               { "id" : "17", "name" : "James A. Mollenbeck" },
               { "id" : "18", "name" : "Fraser C. Small" }
          ],
       count : 0,     
        };        
    }
    
    render() {
        return(
            <div className="container">
                <HPJonesTable
                    tableHeader = 'Charter Members'
                    columnCount = {4}
                    tableData = {this.state.names}
                >
            </HPJonesTable>
            </div>
    );
    }    
    
}

export default CharterMembers;