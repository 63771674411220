/*
 *    Date        Initials    Comments
 *    ----------  ----------  ----------------------------------------------
 *    2018-11-07  ahj         initial implementation
 */

import React, {Component} from 'react';
import ContactUsForm from './ContactUsForm.jsx';

class ContactUsComponent extends Component {

  render() {
    return(
        <div>
          <h1 className="App">{this.props.ContactUsInfo}</h1>
          <ContactUsForm/>
        </div>
    );
  }
}

ContactUsComponent.defaultProps = {
    ContactUsInfo: "Wright City Lions Club - Contact Us"
}
export default ContactUsComponent;